<template>
  <CCard>
    <CCardBody>
      <div>
        <MarketCartTable v-if="showProducts" :market="market" />
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapGetters } from "vuex";
import { formatPrice } from "../../../../helpers/common";
import marketMixin from "../../../../mixins/market";
import MarketCartTable from "./MarketCartTable";

export default {
  name: "MarketCartManual",

  mixins: [marketMixin],

  components: {
    MarketCartTable,
  },

  props: {
    market: { type: Object, required: true },
    loadProducts: { type: Boolean, default: false },
  },

  data() {
    return {
      showProducts: false,
    };
  },

  computed: {
    ...mapGetters("connections", ["currencySymbol", "familyId"]),
  },

  mounted() {
    if (this.loadProducts) {
      this.showProducts = true;
    }
  },

  methods: {
    cartTotal() {
      if (this.market.cart) {
        return formatPrice(
          this.market.cart.view.tot,
          this.currencySymbol
        ).format();
      }
      return "";
    },

    openMarket(market) {
      this.$store.commit("shop/setMarket", market);
      this.$store.commit("shop/setCart", {});
      this.$router.push({
        name: "UserMarketDetail",
        params: { familyId: this.familyId, marketId: market.market.id },
      });
    },
  },
};
</script>
